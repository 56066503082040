<template>
  <v-app>
    <v-main>
      <v-container fluid class="h-100">
        <div class="align-center d-flex flex-column h-100 justify-center">
          <img :src="logo" style="max-width: 300px" class="pb-10 pb-md-16" />
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <v-form style="max-width: 500px" @submit.prevent="handleSubmit(sendLoginRequest(null))">
              <v-card elevation="2" :loading="loading">
                <div class="container">
                  <div class="row">
                    <div class="col-12 pt-8 active">
                      <ValidationProvider rules="required" name="Username" v-slot="{ errors }">
                        <v-text-field v-model="username" label="Username" required dense :error-messages="errors" focus>
                          <v-icon slot="prepend"> mdi-account-circle-outline </v-icon>
                          <v-icon v-if="username" slot="append" @click="username = ''">mdi-close</v-icon>
                        </v-text-field>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 active">
                      <ValidationProvider rules="required" name="Password" v-slot="{ errors }">
                        <v-text-field v-model="password" label="Password" required dense :type="isPasswordVisible ? 'password' : 'text'" :error-messages="errors">
                          <v-icon slot="prepend"> mdi-lock-outline </v-icon>
                          <v-icon slot="append" @click="isPasswordVisible = !isPasswordVisible">{{ isPasswordVisible ? "mdi-eye-off-outline" : "mdi-eye-outline" }}</v-icon>
                        </v-text-field>
                      </ValidationProvider>
                      </div>
                    <div v-if="errorMessage" class="col-12 text-center red--text">{{ errorMessage }}</div>
                    <div class="col-12">
                      <v-btn block elevation="2" color="primary" type="submit">Sign in</v-btn>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-form>
          </ValidationObserver>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { login } from '@/api/backend.js'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'Login',
  components: {
    ValidationObserver, ValidationProvider
  },
  data() {
    return {
      logo: require('@/assets/logo.jpg'),
      username: '',
      password: '',
      isPasswordVisible: true,
      errorMessage: '',
      loading: false
    }
  },
  methods: {
    sendLoginRequest(mfaCode) {
      this.loading = true
      login(this.username.trim(), this.password.trim())
        .then((r) => {
          this.$store.dispatch('security/login', r.data)
          this.$router.push({ name: 'Home' })
        })
        .catch((e) => {
          console.log(e)
          if (e.response !== undefined && e.response.status === 401) {
            this.errorMessage = 'Invalid credentials'
          } else {
            this.showErrorNotification('An error occured, try again later')
          }
        }).then(r => { this.loading = false })
    },
    reset() {
      this.username = null
      this.password = null
      this.isPasswordVisible = true
    }
  }
}
</script>
<style scoped>
.active >>> .v-text-field .v-label {
    max-width: 133% !important;
    transform: translateY(-18px) scale(0.75) !important;
    pointer-events: auto !important;
}
</style>
